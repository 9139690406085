import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Row, Col, Tag, Typography, Divider, Form, Card, Button, Spin, Skeleton,
} from 'antd';
import Media from 'react-media';
import { Base } from 'componentlibrary';
import { isEmpty } from 'lodash';

import DACQRCode, { activationUrl } from '../DACQRCode';
import { formatTimestamp } from '../../Utils/TimeUtils';

const { Text } = Typography;

export class DACView extends Base {
  constructor(props) {
    super(props);

    this.state = {
      dac: undefined,
      loading: true,
    };

    this.loading = this.loading.bind(this);
    this.fetchTeam = this.fetchTeam.bind(this);
  }

  get teamUuid() {
    const { match } = this.props;
    return (match && match.params && match.params.uuid) || null;
  }

  get isTeamMemberOrOwner() {
    const { user, team } = this.props;

    if (!team || isEmpty(team)) {
      return undefined;
    }

    const teamMembers = team.members || [];

    return teamMembers.map(
      (member) => member.uuid
    ).includes(user.uuid) || team.owner.uuid === user.uuid;
  }

  componentDidMount() {
    this.fetchTeam(this.teamUuid);
  }

  componentDidUpdate() {
    const { dac } = this.state;
    const { team } = this.props;
    console.log("compoenentditupdateprops", this.props)
    if (dac === undefined && team && Object.keys(team).length > 0) {
      const latestDac = team.dacs && team.dacs.length > 0 && team.dacs[0];
      this.setState({ dac: latestDac || null });
    }
  }

  async fetchTeam(uuid) {
    const { t, getTeam } = this.props;

    try {
      await super.dispatchWithAuth(getTeam, uuid);
      super.onDataRetrieved();
    } catch (err) {
      console.error(err);
      if (err.status === 404) {
        super.handleError(t('erorrs:404'), t('errors:notFound'));
        return;
      }
      this.handleError(err);
    }

    this.loading(false);
  }

  loading(state) {
    this.setState({ loading: state });
  }

  renderData(isMobile) {
    const { dac } = this.state;
    const { t, user } = this.props;

    return (
      <>
        {
          isMobile && (
            <Card className="text-center" style={{ marginBottom: 20 }}>
              <Row>
                <Col>
                  <Button type="primary">
                    <a
                      href={activationUrl(dac.code, user.username)}
                    >
                      {t('tapToActivate')}
                    </a>
                  </Button>
                </Col>
              </Row>
            </Card>
          )
        }
        <Card className={isMobile && 'text-center'}>
          <Row>
            <Col>
              <Form.Item>
                <DACQRCode
                  size={180}
                  dac={dac.code}
                  username={user.username}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Row><Tag style={{ fontSize: 16 }} key={dac.code}>{dac.code}</Tag></Row>
                {
                  dac.createdAt && (
                    <Row><Text>{`${t('createdAt')} ${formatTimestamp(dac.createdAt)}`}</Text></Row>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col>
              {t('appNote')}
            </Col>
          </Row>
        </Card>
        { this.renderMobileAppInfo(isMobile) }
      </>
    );
  }

  renderMobileAppInfo(isMobile) {
    const { t } = this.props;

    return (
      <Card style={{ marginTop: 20 }} className={isMobile && 'text-center'}>
        <Row>
          <Col>
            <h4><strong>{t('downloadApp')}</strong></h4>
          </Col>
        </Row>
        <Row style={{ marginTop: 20, marginBottom: 20 }}>
          <a href="https://apps.apple.com/au/app/forwood-ccv/id1574565890" target="_blank" rel="noopener noreferrer">
            <img alt="Apple store" src="/images/app-store-badge.png" width="200" />
          </a>
        </Row>
        <Row>
          <a href="https://play.google.com/store/apps/details?id=com.forwood.forwood_crm_2" target="_blank" rel="noopener noreferrer">
            <img alt="Google play store" src="/images/google-play-badge.png" width="200" />
          </a>
        </Row>
      </Card>
    );
  }

  renderNoData(isMobile) {
    const { t } = this.props;

    return (
      <>
        <Card style={{ marginBottom: 20 }}>
          {t('noCurrentDacForTeam')}
        </Card>
        {this.renderMobileAppInfo(isMobile)}
      </>
    );
  }

  render() {
    const { t } = this.props;
    const { dac, loading } = this.state;

    const canShowDac = this.isTeamMemberOrOwner;

    return (
      <Media query="(max-width: 769px)">
        {(isMobile) => (
          <Spin
            spinning={loading}
            size="large"
            style={{ maxHeight: '100%' }}
          >
            <Row>
              <Col>
                <h1><strong>{t('deviceActivationCode')}</strong></h1>
                <Divider />
              </Col>
            </Row>
            {
              loading && (
                <>
                  <Card style={{ marginBottom: 20 }}><Skeleton paragraph={{ rows: 8 }} /></Card>
                  <Card><Skeleton paragraph={{ rows: 6 }} /></Card>
                </>
              )
            }
            {
              !loading && (
                dac && canShowDac ? this.renderData(isMobile) : this.renderNoData(isMobile)
              )
            }
          </Spin>
        )}
      </Media>
    );
  }
}

DACView.defaultProps = {
  dac: null
};

DACView.propTypes = {
  t: PropTypes.func.isRequired,
  team: PropTypes.object,
  getTeam: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default withTranslation(['dacView', 'common'])(DACView);
